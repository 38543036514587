import requestApi from 'utility/requestApi'
import Axios from 'axios'

import {
  CREATE_LOCATION_START,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
} from 'redux/actionTypes';


export const GET_AUDIT_REPORT_START = 'GET_AUDIT_REPORT_START'
export const GET_AUDIT_REPORT_SUCCESS = 'GET_AUDIT_REPORT_SUCCESS'
export const GET_AUDIT_REPORT_FAILURE = 'GET_AUDIT_REPORT_FAILURE'

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR'

export const GET_FACILITIES_REQUEST = 'GET_FACILITIES_REQUEST'
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS'
export const GET_FACILITIES_ERROR = 'GET_FACILITIES_ERROR'

export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST'
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR'

export const GET_AUDITS_BY_MONTH_REQUEST = 'GET_AUDITS_BY_MONTH_REQUEST'
export const GET_AUDITS_BY_MONTH_SUCCESS = 'GET_AUDITS_BY_MONTH_SUCCESS'
export const GET_AUDITS_BY_MONTH_ERROR = 'GET_AUDITS_BY_MONTH_ERROR'

export const GET_AREAS_REQUEST = 'GET_AREAS_REQUEST'
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS'
export const GET_AREAS_ERROR = 'GET_AREAS_ERROR'

export const GET_CUSTOM_AREAS_REQUEST = 'GET_CUSTOM_AREAS_REQUEST'
export const GET_CUSTOM_AREAS_SUCCESS = 'GET_CUSTOM_AREAS_SUCCESS'
export const GET_CUSTOM_AREAS_ERROR = 'GET_CUSTOM_AREAS_ERROR'

export const LOAD_FLOOR_REQUEST = 'LOAD_FLOOR_REQUEST'
export const LOAD_FLOOR_SUCCESS = 'LOAD_FLOOR_SUCCESS'
export const LOAD_FLOOR_ERROR = 'LOAD_FLOOR_ERROR'

export const LOAD_GENERAL_REQUEST = 'LOAD_GENERAL_REQUEST'
export const LOAD_GENERAL_SUCCESS = 'LOAD_GENERAL_SUCCESS'
export const LOAD_GENERAL_ERROR = 'LOAD_GENERAL_ERROR'

export const LOAD_TIME_N_TASK_REQUEST = 'LOAD_TIME_N_TASK_REQUEST'
export const LOAD_TIME_N_TASK_SUCCESS = 'LOAD_TIME_N_TASK_SUCCESS'
export const LOAD_TIME_N_TASK_ERROR = 'LOAD_TIME_N_TASK_ERROR'

export const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST'
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_ERROR = 'LOAD_PRODUCTS_ERROR'

export const GET_AVERAGE_AUDITS_REQUEST = 'GET_AVERAGE_AUDITS_REQUEST'
export const GET_AVERAGE_AUDITS_SUCCESS = 'GET_AVERAGE_AUDITS_SUCCESS'
export const GET_AVERAGE_AUDITS_ERROR = 'GET_AVERAGE_AUDITS_ERROR'

export const GET_HIGHEST_AUDITS_REQUEST = 'GET_HIGHEST_AUDITS_REQUEST'
export const GET_HIGHEST_AUDITS_SUCCESS = 'GET_HIGHEST_AUDITS_SUCCESS'
export const GET_HIGHEST_AUDITS_ERROR = 'GET_HIGHEST_AUDITS_ERROR'

export const GET_LOWEST_AUDITS_REQUEST = 'GET_LOWEST_AUDITS_REQUEST'
export const GET_LOWEST_AUDITS_SUCCESS = 'GET_LOWEST_AUDITS_SUCCESS'
export const GET_LOWEST_AUDITS_ERROR = 'GET_LOWEST_AUDITS_ERROR'

export const GET_EXTERNAL_AREAS_REQUEST = 'GET_EXTERNAL_AREAS_REQUEST'
export const GET_EXTERNAL_AREAS_SUCCESS = 'GET_EXTERNAL_AREAS_SUCCESS'
export const GET_EXTERNAL_AREAS_ERROR = 'GET_EXTERNAL_AREAS_ERROR'

export const loadLocations = payload => dispatch =>
  dispatch({
    type: GET_LOCATIONS_REQUEST,
    meta: requestApi.get(`/api/v1/location${payload.value}`)
      .then(response =>
        dispatch({
          type: GET_LOCATIONS_SUCCESS,
          payload: {
            items: response.data,
            page: payload.page ? payload.page : 1
          }
        })
      )
      .catch(e => dispatch({ type: GET_LOCATIONS_ERROR, payload: e }))
  })

export const loadLocationsSchedule = payload => dispatch =>
  dispatch({
    type: GET_LOCATIONS_REQUEST,
    meta: requestApi.get(`/api/v1/location/GetSchedule${payload.value}`)
      .then(response =>
        dispatch({
          type: GET_LOCATIONS_SUCCESS,
          payload: {
            items: response.data,
            page: payload.page ? payload.page : 1
          }
        })
      )
      .catch(e => dispatch({ type: GET_LOCATIONS_ERROR, payload: e }))
  })

export const getAuditsByMonth = payload => dispatch =>
  dispatch({
    type: GET_AUDITS_BY_MONTH_REQUEST,
    meta: requestApi.get(`/api/v1/location/${payload}/GetTotalByMonth`)
      .then(response =>
        dispatch({
          type: GET_AUDITS_BY_MONTH_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_AUDITS_BY_MONTH_ERROR, payload: e }))
  })

export const getAverageAudit = payload => dispatch =>
  dispatch({
    type: GET_AVERAGE_AUDITS_REQUEST,
    meta: requestApi.get(`/api/v1/location/${payload}/GetAverageAudit`)
      .then(response =>
        dispatch({
          type: GET_AVERAGE_AUDITS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_AVERAGE_AUDITS_ERROR, payload: e }))
  })

export const getHighestAudit = payload => dispatch =>
  dispatch({
    type: GET_HIGHEST_AUDITS_REQUEST,
    meta: requestApi.get(`/api/v1/location/${payload}/GetHighestAudit`)
      .then(response =>
        dispatch({
          type: GET_HIGHEST_AUDITS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_HIGHEST_AUDITS_ERROR, payload: e }))
  })

export const getLowestAudit = payload => dispatch =>
  dispatch({
    type: GET_LOWEST_AUDITS_REQUEST,
    meta: requestApi.get(`/api/v1/location/${payload}/GetLowestAudit`)
      .then(response =>
        dispatch({
          type: GET_LOWEST_AUDITS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_LOWEST_AUDITS_ERROR, payload: e }))
  })

export const loadSingleLocation = payload => dispatch =>
  dispatch({
    type: GET_LOCATION_REQUEST,
    meta: requestApi.get(process.env.REACT_APP_API_URL + '/api/v1/Location/' + payload.id + '/GetLocation')
      .then(response =>
        dispatch({
          type: GET_LOCATION_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_LOCATION_ERROR, payload: e }))
  })

export const loadAreas = payload => dispatch =>
  dispatch({
    type: GET_AREAS_REQUEST,
    meta: requestApi.get(process.env.REACT_APP_API_URL + '/api/v1/Area')
      .then(response =>
        dispatch({
          type: GET_AREAS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_AREAS_ERROR, payload: e }))
  })

export const loadCustomAreas = payload => dispatch =>
  dispatch({
    type: GET_CUSTOM_AREAS_REQUEST,
    meta: requestApi.get(process.env.REACT_APP_API_URL + '/api/v1/CustomArea')
      .then(response =>
        dispatch({
          type: GET_CUSTOM_AREAS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_CUSTOM_AREAS_ERROR, payload: e }))
  })

export const getExternalAreas = () => async dispatch => {
  dispatch({ type: GET_EXTERNAL_AREAS_REQUEST })
  try {
    const response = await Axios.get('https://facilicheck.mojolicious.net/areas')
    dispatch({
      type: GET_EXTERNAL_AREAS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({ type: GET_EXTERNAL_AREAS_ERROR, payload: error });
  }
}

export const getFacilities = () => dispatch => (
  dispatch({
    type: GET_FACILITIES_REQUEST,
    meta: Axios.get('https://facilicheck.mojolicious.net/facility-types')
      .then(response =>
        dispatch({
          type: GET_FACILITIES_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: GET_FACILITIES_ERROR, payload: e }))
  })
);

export const loadFloorIssues = payload => dispatch =>
  dispatch({
    type: LOAD_FLOOR_REQUEST,
    meta: Axios.get('https://facilicheck.mojolicious.net/floor-issues')
      .then(response =>
        dispatch({
          type: LOAD_FLOOR_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: LOAD_FLOOR_ERROR, payload: e }))
  })

export const loadGeneralIssues = payload => dispatch =>
  dispatch({
    type: LOAD_GENERAL_REQUEST,
    meta: Axios.get('https://facilicheck.mojolicious.net/general-issues')
      .then(response =>
        dispatch({
          type: LOAD_GENERAL_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: LOAD_GENERAL_ERROR, payload: e }))
  })

export const loadTimeAndTaskReferences = payload => dispatch =>
  dispatch({
    type: LOAD_TIME_N_TASK_REQUEST,
    meta: Axios.get('https://facilicheck.mojolicious.net/time-and-task-references')
      .then(response =>
        dispatch({
          type: LOAD_TIME_N_TASK_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: LOAD_TIME_N_TASK_ERROR, payload: e }))
  })

export const loadProducts = payload => dispatch =>
  dispatch({
    type: LOAD_PRODUCTS_REQUEST,
    meta: Axios.get('https://facilicheck.mojolicious.net/scjp-products')
      .then(response =>
        dispatch({
          type: LOAD_PRODUCTS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: LOAD_PRODUCTS_ERROR, payload: e }))
  })

export const createLocation = payload => async dispatch => {
  dispatch({ type: CREATE_LOCATION_START })
  try {
    const { data } = await requestApi.post(`/api/v1/Location`, payload);
    dispatch({ type: CREATE_LOCATION_SUCCESS, payload: data })
    return data;
  } catch (err) {
    dispatch({
      type: CREATE_LOCATION_FAILURE,
      payload: err,
    })
  }
}

export const getAuditReport = payload => async dispatch => {
  dispatch({ type: GET_AUDIT_REPORT_START })
  try {
    const { data } = await requestApi.get(`/api/v1/Location/GetAuditReport/${payload.id}`, payload, { headers: { 'Content-Disposition': "attachment;filename=report.xls", 'Content-Type': "application/vnd.ms-excel" } });
    dispatch({ type: GET_AUDIT_REPORT_SUCCESS, payload: data })
    return data;
  } catch (err) {
    dispatch({
      type: GET_AUDIT_REPORT_FAILURE,
      payload: err,
    })
  }
}
